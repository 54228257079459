<script>
import Layout from '@layouts/list-overview/list-overview'
import OverviewCard from '@components/overview-card/overview-card'
import Loader from '@components/custom-loader/custom-loader'
import Subheader from '@components/page-sub-header/page-sub-header'
import Sort from '@components/sort-selection/sort-selection'
import Router from '@src/router/index'
import i18n from '@src/i18n'
import sortCountries from '@utils/sortCountries'
import { saveStateSession, getSavedStateSession } from '@utils/storage'
import {
  enterpriseComputed,
  enterpriseMethods,
} from '@state/helpers/enterprise'
import {
  expenseOverviewMethods,
  expenseOverviewComputed,
  expenseOverviewMutations,
} from '@state/helpers/expenseOverview'
import { expenseModalComputed } from '@state/helpers/expense-modal'
import { pick } from 'lodash'

export default {
  components: {
    Layout,
    OverviewCard,
    Loader,
    Subheader,
    Sort,
  },
  page: {
    title: i18n.t('OVERVIEW'),
    meta: [{ name: 'description', content: 'ExpensesOverview' }],
  },

  data() {
    return {
      sortOptions: [
        {
          text: this.$i18n.t('SORT_BY.COUNTRY'),
          value: 'country',
        },
        {
          text: this.$i18n.t('SORT_BY.MOST_EXPENSES_THAT_NEED_INFO'),
          value: 'expenses_need_info',
        },
        {
          text: this.$i18n.t('SORT_BY.HIGHEST_POTENTIAL_RECLAIM'),
          value: 'potential_reclaim_sum_enterprise_currency',
        },
        {
          text: this.$i18n.t('SORT_BY.HIGHEST_READY_FOR_RECLAIM'),
          value: 'ready_for_reclaim_sum_enterprise_currency',
        },
      ],
    }
  },

  computed: {
    ...expenseOverviewComputed,
    ...enterpriseComputed,
    ...pick(expenseModalComputed, ['isShowingExpenseModal']),
    currentCountries() {
      const indexBegin = (this.pagination.page - 1) * this.pagination.pageSize
      const indexEnd = this.pagination.pageSize + indexBegin
      const sortedCountries = sortCountries([...this.countries], this.sort)
      return sortedCountries.slice(indexBegin, indexEnd)
    },
  },

  watch: {
    currentEnterpriseId(newValue, oldValue) {
      if (oldValue !== newValue && newValue) {
        this.getCountries()
      }
    },
  },

  mounted() {
    if (
      !(
        getSavedStateSession('isVattaxAdmin') ||
        getSavedStateSession('isReadOnlyUser')
      ) &&
      !getSavedStateSession('selectedEnterprise')
    ) {
      this.getCurrentEnterprise()
    }
    if (this.currentEnterpriseId) {
      return this.getCountries()
    }
  },

  methods: {
    ...expenseOverviewMethods,
    ...expenseOverviewMutations,
    ...enterpriseMethods,
    setCurrentPage(page) {
      return this.setPage(page)
    },
    onChangeYear(value) {
      saveStateSession('expenseOverview.year', value)
      return this.setYear(value).then(() => this.getCountries())
    },
    onChangeSort(value) {
      this.$amplitude.getInstance().logEvent('SORT_COUNTRY_OVERVIEW', {
        sortedBy: value,
      })
      return this.setSort(value)
    },
    onCardClick(year, country) {
      // First update year and country in store so they are known before expenses-by-country is mounted
      this.SET_YEAR(year)
      saveStateSession('expensesByCountry.year', year)
      this.SET_COUNTRY(country)
      this.$amplitude.getInstance().logEvent('SELECT_COUNTRY_TO_REVIEW', {
        year: year,
        country: country,
      })
      Router.push(`/expenses/${country}`)
    },
  },
}
</script>

<template>
  <Layout>
    <Subheader
      :title="$t('EXPENSES')"
      :on-change="onChangeYear"
      :default-value="year"
      :enterprise-id="currentEnterpriseId"
    />
    <Sort
      :value="{
        text: '',
        value: sort,
      }"
      :options="sortOptions"
      :on-change="onChangeSort"
    />
    <Loader v-if="getCountriesInProgress || getCurrentEnterpriseInProgress" />

    <div v-else-if="currentCountries.length">
      <OverviewCard
        v-for="(item, index) in currentCountries"
        :key="index"
        :country="item.country"
        :currency="item.enterprise_currency"
        :period="item.year"
        :need-info="item.expenses_need_info"
        :potential-reclaim="item.potential_reclaim_sum_enterprise_currency"
        :ready-for-reclaim="item.ready_for_reclaim_sum_enterprise_currency"
        :on-card-click="onCardClick"
      />
      <v-pagination
        v-if="pagination.total > pagination.pageSize"
        :value="pagination.page"
        class="custom"
        total-visible="7"
        :next-icon="$t('NEXT')"
        :prev-icon="$t('PREVIOUS')"
        :length="Math.ceil(pagination.total / pagination.pageSize)"
        @input="setCurrentPage"
      />
    </div>
    <div v-else-if="!countries.length && !getCountriesError">
      {{ $t('NO_EXPENSES_MESSAGE') }}
    </div>
    <div v-else-if="getCountriesError">
      {{ $t('EXPENSES_LOADING_ERROR') }}
    </div>
  </Layout>
</template>
