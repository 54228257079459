import { mapState, mapActions, mapMutations } from 'vuex'

export const expenseOverviewComputed = {
  ...mapState('expenseOverview', {
    countries: (state) => state.countries,
    getCountriesInProgress: (state) => state.getCountriesInProgress,
    getCountriesError: (state) => state.getCountriesError,
    pagination: (state) => state.pagination,
    year: (state) => state.year,
    sort: (state) => state.sort,
  }),
}

export const expenseOverviewMethods = mapActions('expenseOverview', [
  'getCountries',
  'setYear',
  'setPage',
  'setSort',
])

export const expenseOverviewMutations = mapMutations('expensesByCountry', [
  'SET_YEAR',
  'SET_COUNTRY',
])
