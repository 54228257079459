import i18n from '@src/i18n'

export default function (countries, sort) {
  let sortedCountries = []
  if (sort === 'country') {
    sortedCountries = countries.sort(function (a, b) {
      const nameA = i18n.t(`ALPHA3_COUNTRY.${a.country}`)
      const nameB = i18n.t(`ALPHA3_COUNTRY.${b.country}`)
      return nameA.localeCompare(nameB)
    })
  } else if (sort === 'potential_reclaim_sum_enterprise_currency') {
    sortedCountries = countries.sort((a, b) => {
      const firstValue =
        a.potential_reclaim_sum_enterprise_currency +
        a.ready_for_reclaim_sum_enterprise_currency
      const secondValue =
        b.potential_reclaim_sum_enterprise_currency +
        b.ready_for_reclaim_sum_enterprise_currency

      return secondValue - firstValue
    })
  } else {
    sortedCountries = countries.sort((a, b) => b[sort] - a[sort])
  }

  return sortedCountries
}
