<script>
import EnterpriseOriginToggle from '@components/enterprise-origin-toggle/enterprise-origin-toggle.vue'
import { lunadisPayOrigin } from '@/src/constants/origins'
export default {
  components: {
    EnterpriseOriginToggle,
  },
  props: {
    country: {
      default: 'DK',
      type: String,
    },
    period: {
      default: null,
      type: Number,
    },
    needInfo: {
      default: null,
      type: Number,
    },
    potentialReclaim: {
      default: null,
      type: Number,
    },
    readyForReclaim: {
      default: null,
      type: Number,
    },
    currency: {
      default: null,
      type: String,
    },
    onCardClick: {
      type: Function,
      default: () => null,
    },
  },
  data() {
    return {
      lunadisPayOrigin,
    }
  },
  computed: {
    barLength() {
      return (
        (this.readyForReclaim /
          (this.potentialReclaim + this.readyForReclaim)) *
        100
      )
    },
    countryName() {
      return this.$i18n.t(`ALPHA3_COUNTRY.${this.country}`)
    },
  },
}
</script>

<template>
  <div class="overview" @click="onCardClick(period, country)">
    <v-layout>
      <v-flex class="flag">
        <img
          :src="require(`../../assets/flags/${country}.png`)"
          :alt="country"
          loading="lazy"
          height="34"
          width="48"
        />
      </v-flex>
      <v-flex class="title">
        <h3>{{ countryName }}</h3>
      </v-flex>
      <v-flex class="period">
        <div>
          <span>{{ $t('PERIOD') }}</span>
          {{ period }}
        </div>
      </v-flex>
      <v-flex class="needInfo">
        <div>
          <span>{{ $t('EXPENSES_NEED_INFO') }}</span>
          {{ needInfo }}
        </div>
      </v-flex>
      <v-flex class="progress">
        <EnterpriseOriginToggle :origin="lunadisPayOrigin">
          <template slot="fallback">
            <v-progress-linear
              :value="barLength"
              color="bgcolor__ready-for-reclaim"
              background-color="bgcolor__light-grey"
              height="9"
              data-test-id="overview-progress"
            ></v-progress-linear>
          </template>
        </EnterpriseOriginToggle>
        <div class="moreinfo">
          <div class="label">{{ $t('POTENTIAL_RECLAIM') }}</div>
          <div class="amount">
            {{ this.$tn(potentialReclaim + readyForReclaim) }}
            {{ currency }}</div
          >
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>

<style scoped lang="scss">
@import '@design';

.overviewCardLink {
  text-decoration: none;
  &:hover,
  &:focus {
    text-decoration: none;
  }
}
.overview {
  display: flex;
  align-items: center;
  min-height: 100px;
  padding: 0 120px 0 30px;
  margin: 0 0 10px;
  background: #fff;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='46' height='46'%3e%3cg fill='none' fill-rule='evenodd'%3e%3cpath fill='%23F6F7F8' d='M22.5 0h1C35.9264069 0 46 10.0735931 46 22.5v1C46 35.9264069 35.9264069 46 23.5 46h-1C10.0735931 46 0 35.9264069 0 23.5v-1C0 10.0735931 10.0735931 0 22.5 0z'/%3e%3cpath fill='%23222F44' fill-rule='nonzero' d='M25.5857864 23l-6.7928932-6.7928932c-.3905243-.3905243-.3905243-1.0236893 0-1.4142136.3905243-.3905243 1.0236893-.3905243 1.4142136 0l7.5 7.5c.3905243.3905243.3905243 1.0236893 0 1.4142136l-7.5 7.5c-.3905243.3905243-1.0236893.3905243-1.4142136 0-.3905243-.3905243-.3905243-1.0236893 0-1.4142136L25.5857864 23z'/%3e%3c/g%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 30px top 50%;
  border-radius: 7px;

  // hover animation
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  transition: 1s cubic-bezier(0.23, 1.02, 0.28, 1.12);
  transform: translate3d(0, 0, 0);

  &:hover,
  &:focus {
    cursor: pointer;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='46' height='46'%3e%3cg fill='none' fill-rule='evenodd'%3e%3cpath fill='%23e6eaed' d='M22.5 0h1C35.9264069 0 46 10.0735931 46 22.5v1C46 35.9264069 35.9264069 46 23.5 46h-1C10.0735931 46 0 35.9264069 0 23.5v-1C0 10.0735931 10.0735931 0 22.5 0z'/%3e%3cpath fill='%23222F44' fill-rule='nonzero' d='M25.5857864 23l-6.7928932-6.7928932c-.3905243-.3905243-.3905243-1.0236893 0-1.4142136.3905243-.3905243 1.0236893-.3905243 1.4142136 0l7.5 7.5c.3905243.3905243.3905243 1.0236893 0 1.4142136l-7.5 7.5c-.3905243.3905243-1.0236893.3905243-1.4142136 0-.3905243-.3905243-.3905243-1.0236893 0-1.4142136L25.5857864 23z'/%3e%3c/g%3e%3c/svg%3e");
    box-shadow: 0 6px 24px 0 rgba(0, 0, 0, 0.11);
    transition: 1s cubic-bezier(0.23, 1.02, 0.28, 1.12);
    transform: translate3d(0, -2px, 0);
  }

  .flex {
    position: relative;
    display: flex;
    flex: auto;
    align-items: center;
    padding: 0 0 0 30px;
    margin-left: 30px;
    font-size: 12px;
    font-weight: 700;
    color: $dark;
    border-left: 1px solid $light-grey;

    span {
      margin-right: 10px;
      font-weight: 300;
      color: $grey;
    }

    &:first-child {
      border: none;
    }

    &.flag {
      max-width: 70px;
      padding-left: 0;
      margin: 0 20px 0 0;

      img {
        border-radius: 4px;
      }
    }

    &.title {
      flex: 1;
      flex-grow: 100;
      padding-left: 0;
      margin-left: 0;
      font-size: 24px;
      font-weight: 600;
      color: $dark;
      text-align: left;
      border-left: 0;
    }

    &.period {
      min-width: 110px;
    }
    &.needInfo {
      min-width: 170px;
    }
    &.progress {
      min-width: 250px;
    }
    &.status {
      min-width: 150px;
    }
  }

  h3 {
    font-size: 24px;
    line-height: 28px;
  }

  .moreinfo {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    margin-top: 10px;
  }

  .progress {
    flex-direction: column;
    .label,
    .amount {
      font-weight: 300;
      color: $grey;
    }
    .label {
      color: $grey;
    }
    .amount {
      font-weight: 700;
      color: $dark;
    }
  }
}
</style>
