<script>
import NakedDropdown from '@components/naked-dropdown/naked-dropdown'

export default {
  components: { NakedDropdown },
  props: {
    options: {
      type: Array,
      default: () => [],
      required: true,
    },
    value: {
      type: Object,
      default: () => {},
      required: true,
    },
    onChange: {
      type: Function,
      default: () => {},
      required: false,
    },
  },
}
</script>

<template>
  <div class="sortWrapper">
    <NakedDropdown
      :label="$t('GENERAL.SORT_BY')"
      :items="options"
      :default-value="value"
      right-aligned
      @change="onChange"
    />
  </div>
</template>

<style lang="scss" scoped>
.sortWrapper {
  display: flex;
  justify-content: flex-end;
}
</style>
